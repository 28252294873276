<template lang="pug">
  v-container(fluid).pa-0
    v-menu(
      bottom
      max-width="400"
      :close-on-content-click="false"
      v-model="menu"
    )
      template(v-slot:activator="{ on, attrs }")
        v-btn.ma-0(
          color="green"
          dense
          dark
          block
          v-on="on"
        ) Export Excel
      v-card
        v-toolbar(
          dense
          flat
          dark
          color="blue-grey darken-3"
        )
          span Export Vouchers
        v-container
          v-row
            v-col(cols="6")
              v-autocomplete(
                label="Cost Centers"
                :items="costCenters"
                item-value="id"
                item-text="code"
                v-model="costCenterIds"
                hide-details
                multiple
              )
            v-col(cols="6")
              v-autocomplete(
                label="Paid By"
                item-value="id"
                item-text="code"
                :items="revenueSources"
                v-model="revenueSourceIds"
                hide-details
                multiple
              )
            v-col(cols="12")
              v-row(no-gutters)
                v-col(cols="6")
                  v-text-field(
                    label="Start"
                    type="date"
                    hide-details
                    v-model="dateStart"
                  )
                v-col(cols="6")
                  v-text-field(
                    label="End"
                    type="date"
                    hide-details
                    v-model="dateEnd"
                  )
        v-card-actions
          v-btn(
            text
            dense
            color="green"
            :loading="voucherExportExcelGetting"
            @click="exportExcel"
          ) Export
          v-btn(
            text
            color="red"
            dense
            @click="menu = false"
          ) Cancel
</template>
<script>
import voucherRepository from '@/repositories/voucher.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [voucherExportExcelVars, voucherExportExcelVarNames] = requestVars({ identifier: 'voucher-export-excel', hasData: false })
const voucherExportExcelHandler = new VueRequestHandler(null, voucherExportExcelVarNames)

export default {
  name: 'ExportExcel',
  props: {
    costCenters: Array,
    revenueSources: Array,
  },
  data () {
    return {
      ...voucherExportExcelVars,
      costCenterIds: [],
      dateStart: null,
      dateEnd: null,
      revenueSourceIds: [],
      menu: false,
    }
  },
  computed: {
    exportParams () {
      return {
        costCenters: this.costCenterIds.join(','),
        revenueSources: this.revenueSourceIds.join(','),
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
    },
  },
  methods: {
    async exportExcel () {
      try {
        const handler = voucherExportExcelHandler
        const repository = voucherRepository.exportExcel
        handler.setVue(this)
        const { data } = await handler.execute(repository, [this.exportParams])
        Window.downloadExcel(data, 'voucher')
      } catch (e) {
        alert(e.message)
      }
    },
  },
}
</script>